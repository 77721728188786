//library import
import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//custom import
import ReactSelect from "../../../components/reactSelect/reactSelect";
import { withStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import { overviewData } from "../../../fixtures/dummyApi/dummyApi";
import DateRangePicker from "../../../components/DatePicker";
import { countries } from "../../../fixtures/dummyApi/countries";
//scss
import "./Reports.scss";
import Modal from "../../../components/modal/modal";
import {
  getCurrentReportSubData,
  fetchEditColumnData,
  searchFilterWithColName,
  getFilteredData,
} from "../../../services/overview";
import Input from "../../../components/InputBox/Input";
import { storeIdApiCall } from "../../../services/storeId";
import { withRouter } from "react-router-dom";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import Snackbar from "../../../components/snackbar/snackbar";
import Graph from "./graph";
import Table from "./table";

import { config } from "./graph/graphConfig";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import {getFromLocalStorage, setInLocalStorage, tooltipFormatter} from "../../../lib/helper"

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      date: {
        //start date and end date
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      storedIndex: "",
      loader: true, //loader state
      selected: localStorage.getItem("selectedGroupBy")
        ? JSON.parse(localStorage.getItem("selectedGroupBy"))
        : { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],
      config: config,

      logsData: [], //table data
      columns: [], //table columns

      skip: 0, //starting point
      limit: 50, //ending point
      page: 1, //pagination page
      dataToShow: 50, //50 or 100 data to show
      //  totalData: Number,                              // total no of data

      graph: {},

      summary: {},
      anchorEl: null,
      editColumnData: [],

      noData: false,
      modal: false,
      groupedOptions: [],
      graphSeries: [],
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],

          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterData: [...countries],
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
      seriesToRender: [],
      filterWindow: false,
      checklistDisplay: false,
      columnArray: [15, 16, 18, 19, 21, 22, 23, 25, 26, 27, 28],
      copyOfGroupedCols: [],
      showSnackbar: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      snackBarMessage: "",
      keysArr: [],
      currencyData: getFromLocalStorage("currency"),
      previousCurrencyData: getFromLocalStorage("currency"),
      statusText: "",
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoaded: false,
      showTable: false,
    };
  }

  searchHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    for (let i in option) {
      if (option[i].label !== "N/A") {
        option[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option[i].value;
      }
    }

    filterArray[index].selectedOption = option;
    let arr = [];
    if (option && option.length > 0) {
      // option.map(k => arr.push(k.label))
      option.map((k) => {
        let b = "";
        if (k.label.includes("Is not")) {
          b = k.label.substr(7, k.label.length - 1);
        } else {
          b = k.label.substr(3, k.label.length - 1);
        }
        return arr.push(b);
      });
    }
    this.setState({
      storedIndex: filterArray[index].groupedOptionsSelected.index,
    });
    filter[index].parameter_value = arr;
    this.setState({ filterArray, filterSendToApi: filter }, () => {});
  };

  createData = (obj) => {
    let a = [];
    let arr = Object.keys(obj[0]).map((k) => a.push(k));
    return arr;
  };

  // this function takes index of the box and values (text)
  changeOption = (index, value) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    let cols = [...this.state.groupedOptions];

    let valSeached = [];
    // if(value) {
    if (value.length > 3) {
      searchFilterWithColName(
        filterArray[index].groupedOptionsSelected.index,
        value
      )
        .then((data) => {
          if (
            this.state.storedIndex !==
            filterArray[index].groupedOptionsSelected.index
          ) {
            filterArray[index].selectedOption = [];
            this.setState({ filterArray });
          }

          let optionsArr = [];
          Object.keys(data.data.data.search_result).map((k) =>
            data.data.data.search_result[k].map((x) =>
              optionsArr.push({ label: x, value: x })
            )
          );
          filterArray[index].searchOption = optionsArr;
          Object.keys(data.data.data.search_result).map((k) =>
            valSeached.push(...data.data.data.search_result[k])
          );
          this.setState({ filterArray });
          let indexToDelete;
          for (let i in cols)
            for (let j in cols[i].options)
              if (
                cols[i].options[j].index ===
                filterArray[index].groupedOptionsSelected.index
              )
                indexToDelete = { mainIndex: i, subIndex: j };

          cols[indexToDelete.mainIndex].options.splice(
            indexToDelete.subIndex,
            1
          );
          this.setState({ groupedOptions: cols });
        })
        .catch((err) => console.log("{changeOption err}", err));
    }
    // }
    this.setState({ filterSendToApi: filter });
  };

  checklistHandler = (event) => {
    let a = [...this.state.columnArray];
    if (a.includes(event)) {
      let index = a.indexOf(event);
      a.splice(index, 1);
    } else {
      a.splice(1, 0, event);
    }
    this.setState({ checklistDisplay: false, columnArray: a }, () => {
      // this.getData(
      //   this.state.date.startDate,
      //   this.state.date.endDate,
      //   parseInt(this.state.selected.value),
      //   a
      // );
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true }
      // , () =>
      // this.intermediate()
    );
  };

  tableDateRender = (date) => {
    let format = {
      Hour: "ll H:00",
      Day: "ll",
      Week: "ll",
      // "Hour Of Day": "HH:00",
      Year: "ll",
    };
    if (Object.keys(format).includes(this.state.selected.label)) {
      return moment(date).format(format[this.state.selected.label]);
    } else {
      return date;
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  returnDataWithTwoDecsOrInteger = (n) => {
    if (Number(n) === n && n % 1 === 0) {
      return n;
    } else if (Number(n) === n && n % 1 !== 0) {
      return parseFloat(n.toFixed(2));
    } else if (String(n)) {
      return n;
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState(
      {
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        selectData: groupBy,
        selected: selectedGroupBy,
        loader: true,
      }
      // ,
      // () => this.intermediate()
    );
  };
  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    let newPage;

    if (newVal === 100) {
      newPage = Math.trunc(
        stateCopy.page % 2 === 0 ? stateCopy.page / 2 : stateCopy.page / 2 + 1
      );
    } else {
      newPage = stateCopy.page * 2 - 1;
    }

    stateCopy.page = newPage;
    stateCopy.skip = stateCopy.page * newVal - newVal;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  //get new data
  componentDidUpdate = (prevProps, prevState) => {
    //old params and new params are diff than get new data
    if (
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow ||
      this.state.currencyData !== prevState.currencyData ||
      this.state.storeIdValue  !== prevState.storeIdValue ||
      this.state.selected.value !== prevState.selected.value ||
      this.state.date.startDate !== prevState.date.startDate||
      this.state.date.endDate !== prevState.date.endDate
    ) {
      this.intermediate();
    }
  };

  intermediate = () => {
    this.setState({
      loader: true,
      logsData: [],
      summary: [],
      config: config,
      statusText: "Loading data ...",
    });
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.columnArray
      //  this.state.selectedCity.value
    );
  };

  //get initial data on didmount
  componentDidMount = () => {
    this.getAllStoreId();
    this.intermediate()
    fetchEditColumnData().then((data) => {
      let anotherObj = [];
      Object.keys(data.data.columns).map((k, i) =>
        Object.keys(data.data.columns[k]).map((u, w) => {
          anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
          anotherObj[k]["options"] = data.data.columns[k][u].map((x) => {
            return { label: x.value, value: x.value, index: x.index };
          });
        })
      );
      this.setState({
        editColumnData: data.data.columns,
        copyOfGroupedCols: anotherObj,
      });
    });
  };

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("Error in Report Sub file of TotalOrders Component", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  closeFilterBox = () => {
    this.setState({
      filterWindow: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  applyFilter = () => {
    getFilteredData(
      moment(this.state.date.startDate).unix(),
      moment(this.state.date.endDate).unix(),
      this.state.filterSendToApi
    )
      .then((data) => {
        this.methodToPopulateDataAfterFilter(data.data.data);
      })
      .catch((err) => {
        this.setState({
          showSnackbar: true,
          snackBarMessage: "No data found for selected filter.",
        });
        setTimeout(() => {
          this.setState({ showSnackbar: false });
        }, 2000);
      });
    this.setState({
      filterWindow: false,
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  // this method will copy the data which i have received with filtered API,
  // and display it on table and graph
  methodToPopulateDataAfterFilter = (bigObject) => {
    let mgraph = bigObject.graph;
    let mxcat;
    let columns = Object.keys(bigObject.table[0]); // column name from api
    let filterColumns = this.state.editColumnData.map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    let anotherObj = [];

    Object.keys(this.state.editColumnData).map((k, i) =>
      Object.keys(this.state.editColumnData[k]).map((u, w) => {
        anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
        anotherObj[k]["options"] = this.state.editColumnData[k][u].map((x) => {
          return { label: x.value, value: x.value, index: x.index };
        });
      })
    );

    this.setState({ groupedOptions: anotherObj });
    const groupedOptionsSelected = filterColumns[0];
    let cold = columns.map((item, index) => {
      // modified according to table structure
      return {
        Header: item[0].toUpperCase() + item.slice(1),
        accessor: (data) => {
          if (
            item.toLowerCase().includes("date") &&
            this.state.selected.value < 3
          )
            return moment(Object.values(data)[index]).format("DD-MMMM-YYYY LT");
          else if (item.toLowerCase().includes("orders"))
            return Object.values(data)[index];
          else {
            let type = typeof Object.values(data)[index];
            if (type === "number")
              return (
                Object.values(data)[index] &&
                Object.values(data)[index].toFixed(2)
              );
            else return Object.values(data)[index];
          }
        },
        id: item,
        show: true,
        width: 150,
      };
    });

    if (this.state.selected.value < 3)
      mxcat = mgraph.xcat.map((item) => moment(item).format("DD-MMMM-YYYY LT"));
    else mxcat = mgraph.xcat.map((item) => item);

    mgraph.xcat = mxcat;

    let obj = bigObject.summary;
    obj["Summary"] = "Summary";
    let getOnlyOrders = bigObject.table.map((k) => k.order);

    this.setState({
      seriesToRender: this.createNewArrayToRender(
        mgraph.series[0].data,
        getOnlyOrders
      ),
      columns: cold,
      logsData: bigObject.table,
      graph: mgraph,
      summary: bigObject.summary,
      // groupedOptions: anotherObj,
      groupedOptionsSelected,
      loader: false,
      noData: false,
    });
  };

  //method to fetch data
  getData = (startDate, endDate, sort_by, columnArray) => {
    setInLocalStorage("currency", this.state.currencyData || "INR");
    getCurrentReportSubData(
      moment(startDate).unix(),
      moment(endDate).unix(),
      sort_by,
      columnArray,
      this.state.currencyData,
      this.state.storeIdValue
    )
      .then((data) => {
        let newConfig = this.state.config;
        let series = data.data.data.graph.series;

        newConfig.series = series;
        let columns = Object.keys(data.data.data.table[0]); // column name from api
        let filterColumns = this.state.editColumnData.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        let anotherObj = [];

        Object.keys(this.state.editColumnData).map((k, i) =>
          Object.keys(this.state.editColumnData[k]).map((u, w) => {
            anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
            anotherObj[k]["options"] = this.state.editColumnData[k][u].map(
              (x) => {
                return { label: x.value, value: x.value, index: x.index };
              }
            );
          })
        );

        const groupedOptionsSelected = filterColumns[0];
        columns[0] = this.state.selected.label;
        let cold = columns.map((item, index) => {
          // modified according to table structure
          return {
            Header: item[0].toUpperCase() + item.slice(1),
            accessor: (data) => {
              if (
                item.toLowerCase().includes("date") &&
                this.state.selected.value < 1
              )
                return moment(Object.values(data)[index]).format(
                  "DD-MMMM-YYYY LT"
                );
              else if (item.toLowerCase().includes("orders"))
                return Object.values(data)[index];
              else {
                let type = typeof Object.values(data)[index];
                if (type === "number")
                  return (
                    Object.values(data)[index] &&
                    Object.values(data)[index].toFixed(2)
                  );
                else return Object.values(data)[index];
              }
            },
            id: item,
            show: true,
            width: 150,
          };
        });

        let format = {
          Hour: "ll H:00",
          Day: "ll",
          Week: "ll",
          "Hour Of Day": "HH:00",
        };
        let xCategories;
        if (this.state.selected.label === "Hour") {
          xCategories = data.data.data.graph.xcat.map((dateTime) => {
            return moment(dateTime).format(format[this.state.selected.label]);
          });
        } else if (
          this.state.selected.label === "Day" ||
          this.state.selected.label === "Week"
        ) {
          xCategories = data.data.data.graph.xcat.map((dateTime) => {
            return moment(dateTime).format(format[this.state.selected.label]);
          });
          data.data.data.table = data.data.data.table.map((tableObject) => {
            const date = moment(tableObject.dateTime).format(
              format[this.state.selected.label]
            );
            tableObject.dateTime = date;
            return tableObject;
          });
        } else if (this.state.selected.label === "Hour Of Day") {
          xCategories = data.data.data.graph.xcat.map((dateTime) => {
            return moment(dateTime).format(format[this.state.selected.label]);
          });
        } else {
          xCategories = data.data.data.graph.xcat;
        }

        if (this.state.selected.label === "Hour") {
          data.data.data.table = data.data.data.table.map(
            (tableObject, index) => {
              const date = moment(tableObject.dateTime).format(
                format[this.state.selected.label]
              );
              tableObject.dateTime = date;
              return tableObject;
            }
          );
        }

        if (this.state.selected.label === "Day") {
          data.data.data.table = data.data.data.table.map((tableObject) => {
            const date = moment(tableObject.dateTime).format(
              format[this.state.selected.label]
            );
            tableObject.dateTime = date;
            return tableObject;
          });
        }

        if (this.state.selected.label === "Hour Of Day") {
          data.data.data.table = data.data.data.table.map((tableObject) => {
            const date = moment(tableObject.dateTime).format(
              format[this.state.selected.label]
            );
            tableObject.dateTime = date;
            return tableObject;
          });
        }

        let columnWidth = xCategories.length <= 7 ? "30%" : "100%";
        newConfig.options = {
          ...newConfig.options,
          xaxis: {
            ...newConfig.options.xaxis,
            categories: xCategories,
            title: {
              text: this.state.selected.label,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
              offsetY: -10,

            },
            // labels: {
            //   show: true,
            //   offsetY: -10,
            //   style: {
            //     fontSize: "11px",
            //   },
            // },
          },

          // grid: {
          //   padding: {
          //     left: 60,
          //   },
          // },

          yaxis: {
            ...newConfig.options.yaxis,
            title: {
              text: series[0].name,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
              // offsetX: this.state.selected.value ? 5 : 10,
            },
            labels: {
              offsetX:15,
              // yaxis formatter
              formatter: (val, index) => {
                return tooltipFormatter(val, this.state.currencyData, false);
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: columnWidth,
            },
          },
          tooltip: {
            enabled: true,
            enabledOnSeries: true,
            y: {
              // tooltip formatter it formats the yaxis data when someone hover over graph
              formatter: (val, index) => {
                return tooltipFormatter(val, this.state.currencyData, false);
              },
            },
            followCursor: true,
          },
        };
        this.setState({
          columns: cold,
          logsData: data.data.data.table,
          // graph: mgraph,
          groupedOptions: anotherObj,
          statusText: "",
          showTable: true,
          config: newConfig,
          summary: data.data.data.summary,
          // groupedOptions: anotherObj,
          groupedOptionsSelected,
          loader: false,
          noData: false,
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((e) => {
        console.log("error ===>", e.response);
        if (
          e.response &&
          e.response.data &&
          e.response.data.message &&
          e.response.data.message === "currency conversion not found"
        ) {
          this.setState({
            loader: false,
            noData: false,
            statusText: "Loading data ...",
            snackBarMessage: "currency is not supported",
            showSnackbar: true,
            showTable: false,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        } else {
          this.setState({
            config: config,
            loader: false,
            showTable: false,
            logsData: [],
            summary: [],
            statusText: "No data for selected date range",
            noData: true,
            snackBarMessage: "No data for selected date range",
            showSnackbar: true,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        }
      });
  };

  createNewArrayToRender = (arr, anotherArray) => {
    let arrayToPush = [];
    let object = {};
    arr.map((k, i) => {
      return (
        (object = {}),
        (object["y"] = k),
        (object["Order"] = anotherArray[i]),
        arrayToPush.push(object)
      );
    });
    return arrayToPush;
  };

  addFilter = () => {
    let stateObj = { ...this.state };
    let filterArray = [...stateObj.filterArray];
    let filterObj = [...stateObj.filterSendToApi];
    filterObj.push({
      column: "",
      condition_operator: 1,
      parameter_value: [],
    });
    filterArray.push({
      isIsNotData: [
        { value: "Is", label: "Is" },
        { value: "IsNot", label: "Is not" },
      ],
      isIsNotselected: [{ value: "Is", label: "Is" }],
      searchInput: "",
      selectedOption: [],
      searchOption: [{ value: "", label: "N/A" }],
    });

    this.setState({ filterArray, filterSendToApi: filterObj });
  };

  getCurrencyData = (obj) => {
    this.setState({
      currencyData: obj.currencies[0].code,
    });
    this.handleClose();
  };

  removeFilter = (index) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];

    filter.splice(index, 1);
    filterArray.splice(index, 1);

    this.setState({ filterArray, filterSendToApi: filter });
  };

  changeHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].isIsNotselected = [option];
    filter[index].condition_operator = option.label === "Is" ? 1 : 0;
    let option2 = filterArray[index].selectedOption;

    for (let i in option2) {
      if (option2[i].label !== "N/A") {
        option2[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option2[i].value;
      }
    }

    filterArray[index].selectedOption = option2;

    this.setState({ filterArray, filterSendToApi: filter });
  };

  goBack = () => {
    this.props.history.push("truck-utilisation");
  };

  groupedOptionsSelectedHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let cols = [...this.state.groupedOptions];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].groupedOptionsSelected = option;
    filter[index].column = option.index;

    searchFilterWithColName(option.index, "").then((data) => {
      if (this.state.storedIndex !== option.index) {
        filterArray[index].selectedOption = [];
        if (
          this.state.saveIndexOfDeletedObject.mainIndex &&
          this.state.saveObjectOfDeletedObject
        ) {
          cols[this.state.saveIndexOfDeletedObject.mainIndex].options.push(
            this.state.saveObjectOfDeletedObject
          );
        }
        this.setState({ filterArray, groupedOptions: cols });
      }

      let optionsArr = [];
      Object.keys(data.data.data.search_result).map((k) =>
        data.data.data.search_result[k].map((x) =>
          optionsArr.push({ label: x, value: x })
        )
      );
      filterArray[index].searchOption = optionsArr;
      let indexToDelete;
      let objectOfIndexWhichIsDeleted;
      for (let i in cols)
        for (let j in cols[i].options)
          if (
            cols[i].options[j].index ===
            filterArray[index].groupedOptionsSelected.index
          ) {
            indexToDelete = { mainIndex: i, subIndex: j };
            objectOfIndexWhichIsDeleted = cols[i].options[j];
          }
      cols[indexToDelete.mainIndex].options.splice(indexToDelete.subIndex, 1);
      this.setState({
        groupedOptions: cols,
        filterArray,
        saveIndexOfDeletedObject: indexToDelete,
        saveObjectOfDeletedObject: objectOfIndexWhichIsDeleted,
      });
    });

    this.setState({ filterSendToApi: filter });
  };

  // save button
  openNewUrl = () => {
    const url = window.location.href;
    localStorage.setItem("columns", JSON.stringify(this.state.columnArray));
    localStorage.setItem(
      "startDate",
      JSON.stringify(this.state.date.startDate)
    );
    localStorage.setItem("endDate", JSON.stringify(this.state.date.endDate));
    localStorage.setItem(
      "sortBy",
      JSON.stringify(parseInt(this.state.selected.value))
    );
    window.open(url, "_blank");
  };

  changeFilterWindowState = () => {
    this.setState({
      filterWindow: true,
    });
  };

  isCheckListDisplay = () => {
    this.setState({
      checklistDisplay: !this.state.checklistDisplay,
    });
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase())) !== -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      filterData,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="reportWrapper" ref={this.myRef}>
        <div className="head">
          <div
            className="text-grey cursor-pointer globalFontSize"
            onClick={this.goBack}
          >
            <i className="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">Orders Over Time</div>
          <div className="d-flex text-grey mt-2 globalFontSize">
            <div className="mr-3 db_ptr">
              <ReactToPrint
                trigger={() => (
                  <span>
                    <i className="fas fa-print mr-1"></i>Print
                  </span>
                )}
                content={() => this.myRef.current}
              />
            </div>
            <div className="mr-3 db_ptr" onClick={this.toggleModal}>
              <i className="fas fa-download mr-1"></i>Export
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between globalFontSize">
            <div className="d-flex align-items-center justify-content-center">
              <span className="mr-2">Date Range: </span>
              <div className="mt-2 mb-2">
                <DateRangePicker
                  getGroupBy={true}
                  handleDateChange={this.handleDateRange}
                />
              </div>

              <span className="mr-2 ml-5">Group By: </span>
              <div className="selectBody">
                <ReactSelect
                  city={this.state.selectData}
                  change={this.selectHandler}
                  selected={this.state.selected}
                />
              </div>

              <div className="sortByCurrency">
                Currency:
                <Button
                  className={classes.selectCountryButton}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  {this.state.currencyData}
                </Button>
                <Menu
                  style={{ minHeight: "300px", maxHeight: "300px" }}
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <div className="currency-hover-class">
                    <MenuItem>
                      Currency:
                      <Input
                        id=""
                        className="filterCurrency"
                        {...this.state.currencyFilter.input}
                        onChange={(e) => this.filterCurrency(e)}
                      ></Input>
                    </MenuItem>
                    {this.state.filterData.map((k, index) => (
                      <MenuItem
                        key={index}
                        className={classes.menuItem}
                        onClick={() => this.getCurrencyData(k)}
                      >
                        <span>
                          <img
                            src={k.flags.svg}
                            height={25}
                            width={25}
                            alt="currency"
                          />
                        </span>
                        {/* <span>^</span> */}
                        <span className="pl-3 currencySymbol">
                          {k.currencies[0].symbol}
                        </span>
                        <span className="pl-3">{k.name}</span>
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </div>
            </div>
            <div className="d-flex align-items-center globalFontSize">
              <StoreIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>
          </div>
        </div>
        <div className="body" style={{ top: "-30px" }}>
          {this.state.noData ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "white",
                borderRadius: "3px",
                height: "450px",
                boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
              }}
            >
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            </div>
          ) : (
            <div>
              <Graph
                overviewData={overviewData}
                config={this.state.config}
                selected={this.state.selected}
                loader={this.state.loader}
                statusText={this.state.statusText}
              />
            </div>
          )}
          <div>
            <Table
              showTable={this.state.showTable}
              loader={this.state.loader}
              changeFilterWindowState={() => this.changeFilterWindowState()}
              isCheckListDisplay={() => this.isCheckListDisplay()}
              checklistDisplay={this.state.checklistDisplay}
              editColumnData={this.state.editColumnData}
              checklistHandler={(index) => this.checklistHandler(index)}
              columnArray={this.state.columnArray}
              filterWindow={this.state.filterWindow}
              filterArray={this.state.filterArray}
              columns={this.state.columns}
              summary={this.state.summary}
              logsData={this.state.logsData}
              page={this.state.page}
              changeOption={(index) => this.changeOption(index)}
              groupedOptions={this.state.groupedOptions}
              closeFilterBox={this.closeFilterBox}
              groupedOptionsSelectedHandler={(index, options) =>
                this.groupedOptionsSelectedHandler(index, options)
              }
              groupedOptionsSelected={this.state.groupedOptionsSelected}
              changeHandler={(index) => this.changeHandler(index)}
              removeFilter={(index) => this.removeFilter(index)}
              searchHandler={(index) => this.searchHandler(index)}
              searchOption={(index) => this.searchOption(index)}
              addFilter={this.addFilter}
              // closeFilterBox={this.closeFilterBox}
              applyFilter={this.applyFilter}
              changeActivePage={this.changeActivePage}
              returnDataWithTwoDecsOrInteger={(index) =>
                this.returnDataWithTwoDecsOrInteger(index)
              }
              dataToShowHandler={this.dataToShowHandler}
              dateRender={(data) => this.tableDateRender(data)}
              dataToShow={this.state.dataToShow}
            />
          </div>
        </div>
        {/* To Export the Report this modal will open and will confirm either user wants to export the data as csv or not */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>

                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Report));
