import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";

// currencyFormatter.format(10000, {code: "USD"})

class OnlineStoreConversion extends Component {
  render() {
    if (!this.props.data) {
      return (
        <div className="card ">
          <div className="spaceBetween mt-3 ml-3 mr-3">
            <span className="label globalFontSize fontweightone">
              conversion rate
            </span>
            <span className="link globalFontSize fontweightone">
              {/* <Link url={`funnel-chart`}>View Activity</Link> */}
              {/* <Link>View Activity</Link> */}
            </span>
          </div>
          <div className="spaceBetween ml-3 mr-3">
                <span className="numericValue">
                  {this.props.loader ? <RevLoader /> : <></>}
                </span>
              </div>
          <div
            className="text-center no-data"
            style={{ fontSize: "12px", fontWeight: 400 }}
          >
            There were no online store conversion in this date range.
          </div>
        </div>
      );
    }
    return (
      <div className="card ">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            conversion rate
          </span>
          <span className="link globalFontSize fontweightone">
            {/* <Link url={`funnel-chart`}>View Activity</Link> */}
            {/* <Link>View Activity</Link> */}
          </span>
        </div>

        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.data && this.props.data.overall_percent ? (
              `${this.props.data.overall_percent}%`
            ) : (
              "0%"
            )}
          </span>
          <span>{" - "}</span>
        </div>
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="">CONVERSION FUNNEL</span>
        </div>
        <div className="d-flex flex-column mt-3 ml-3 mr-3">
          <span className="" style={{ fontSize: "14px" }}>
            Added to cart
          </span>
          <div className="spaceBetween session">
            <span className="">
              {this.props.data && this.props.data.cart
                ? this.props.data.cart+" "
                : 0+" "}
              sessions
            </span>
            <div className="d-flex justify-between">
              <span>
                {this.props.data && this.props.data.percent_cart
                  ? this.props.data.percent_cart.toFixed(2) + "%"
                  : "0%"}
              </span>
              <span> </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-3 ml-3 mr-3">
          <span className="" style={{ fontSize: "14px" }}>
            Reached checkout
          </span>
          <div className="spaceBetween session">
            <span className="">
              {this.props.data && this.props.data.checkout
                ? this.props.data.checkout+" "
                : 0+" "}
              sessions
            </span>
            <div className="spaceBetween">
              <span className="">
                {this.props.data && this.props.data.percent_checkout
                  ? this.props.data.percent_checkout.toFixed(2) + "%"
                  : "0%"}
              </span>
              <span> </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-3 ml-3 mr-3">
          <span className="" style={{ fontSize: "14px" }}>
            Sessions converted
          </span>
          <div className="spaceBetween session">
            <span className="">
              {this.props.data && this.props.data.conversion
                ? this.props.data.conversion+" "
                : 0+" "}
              sessions
            </span>
            <div className="spaceBetween">
              <span className="">
                {this.props.data && this.props.data.percent_conversion
                  ? this.props.data.percent_conversion.toFixed(2) + "%"
                  : "0%"}
              </span>
              <span> </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-3 ml-3 mr-3">
          {/* Added this line for the below space. */}
        </div>
      </div>
    );
  }
}

export default OnlineStoreConversion;
