import React from "react";
import moment from "moment";
import {  Menu, MenuItem, Paper, withStyles } from "@material-ui/core";
import Input from "../../../../components/InputBox/Input";
// Custom Import
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import ReactToPrint from "react-to-print";
import { overviewData } from "../../../../fixtures/dummyApi/dummyApi";
import { countries } from "../../../../fixtures/dummyApi/countries";
// import StatusIdDropDown from "../../../../components/StatusIdDropdown";

// SCSS
import "../../../../static/scss/globalComponent.scss";

import Modal from "../../../../components/modal/modal";
import {
  fetchRideFareData,
  fetchRideSalesData,
  fetchRideTableFare,
} from "../../../../services/rideRevenue";
import { withRouter } from "react-router-dom";
import Pbutton from "../../../../components/button/Button";
import { CSVLink } from "react-csv";
import Snackbar from "../../../../components/snackbar/snackbar";
import Graph from "./graph";
import Table from "./table/table";

import {
  tooltipFormatter,
  // isFloat,
  // setInLocalStorage,
  dateTimeFormatForGraph,
} from " ../../../src/lib/helper";

import {
  getCities,
  getCountries,
  getZones,

} from "../../../../services/rideLocationFilter";
import _ from "lodash";
import Filters from "../location/filter";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class RideRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isMoreThanThousand: false,


      sortByDevice: [
        { label: "All", value: 0 },
        { label: "Android", value: 1 },
        { label: "iOS", value: 2 },
      ],
      selectedSortByDevice: { label: "All", value: 0 },

      tableData: [],
      logsData: [],
      totalSale: 0,

      loader: true,
      noData: false,
      dataLoaded: false,

      page: 1, //pagination page
      dataToShow: 50, //50 or 100 data to show

    initialCharName : window && window._env_ && window._env_.INITIAL_CHART_NAME ?  window._env_.INITIAL_CHART_NAME : "Trucker",
    graph: {},

      summary: {},
      anchorEl: null,

      modal: false,

      revenueBoxData: {},

      showSnackbar: false,
      snackBarMessage: "",

      currencyData: localStorage.getItem("currency")
        ? JSON.parse(localStorage.getItem("currency"))
        : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "INR",

      previousCurrencyData: localStorage.getItem("currency")
        ? JSON.parse(localStorage.getItem("currency"))
        : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "INR",


      // currencyData: window._env_.CURRENCY,
      // previousCurrencyData: window._env_.CURRENCY,

      statusText: "",
      currentRevenue: 0,
      perviousRevenue: 0,
      revenueGrowthStatus: 0,
      revenueMessage: "",
      filterData: [...countries],
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
      // storeIdValue: "0",
      // storeData: statusFilter,
      condition: 0,
      showTable: false,

      country: "",
      city: "",
      countryId: "",
      cityId: "",
      countries: [],
      cities: [],

      date: {
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },

      selected: localStorage.getItem("selectedGroupBy")
        ? JSON.parse(localStorage.getItem("selectedGroupBy"))
        : { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],
    };
  }

  //get new data
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow ||
      this.state.currencyData !== prevState.currencyData ||
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate !== prevState.date.endDate ||
      // prevState.storeIdValue !== this.state.storeIdValue ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      this.state.selected.value !== prevState.selected.value
    ) {
      this.intermediate();
    }
    if (
      this.state.currencyData !== prevState.currencyData ||
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate !== prevState.date.endDate ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId 
    ) {
      this.revenueBox(
        this.state.date.startDate,
        this.state.date.endDate,
        this.state.currencyData,
        this.state.selectedSortByDevice.value,
        this.state.countryId,
        this.state.cityId,
      );
    }

    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],

      });
    }
  };

  //get initial data on didmount
  componentDidMount = () => {
    this.handleFilterData("", "initialCall");
    this.intermediate();
    this.revenueBox(
      this.state.date.startDate,
      this.state.date.endDate,
      this.state.currencyData,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  revenueBox = (startDate, endDate, currency, device, country, city) => {
    fetchRideSalesData(
      moment(startDate).unix(),
      moment(endDate).unix(),
      currency,
      device,
      country || "",
      city || "",
    )
      .then((res) => {
        this.setState({
          revenueBoxData: res.data,
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log("res revenue box error", err);
      });
  };

  intermediate = () => {
    this.setState({
      loader: true,
      logsData: [],
      summary: [],
      statusText: "Loading data ...",
    });

    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.currencyData,
      // this.state.storeIdValue,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  // Method to Fetch Table Data
  getTableData = (
    startDate,
    endDate,
    groupBy,
    currency,
    device,
    countryId,
    cityId,
  ) => {
    fetchRideTableFare(
      moment(startDate).unix(),
      moment(endDate).unix(),
      groupBy || 0,
      currency,
      device,
      countryId || "",
      cityId || "",
    )
      .then((res) => {
        let arrayCollection = [];
        let exportData = [];
        let history = [];
        let data = {};

        res.data.data.table.totalAmount.fare.map((item, index) => {
          Object.keys(res.data.data.history).map((data, dataIndex) => {
            if (
              this.state.selected.value === 3 ||
              this.state.selected.value === 7
            ) {
              if (
                data ===
                res.data.data.table.totalAmount.bookingDateTimestamp[index]
              ) {
                history = res.data.data.history[data];
              }
            } else {
              if (index === dataIndex) {
                history = res.data.data.history[data];
              }
            }
            return history;
          });

          data = {
            fare: res.data.data.table.totalAmount.fare[index],
            timeStamp: dateTimeFormatForGraph(
              this.state.selected.label || "Hour",
              res.data.data.table.totalAmount.bookingDateTimestamp
            )[index],

            history,
          };
          return arrayCollection.push(data);
        });

        arrayCollection.map((object) =>
          object.history.map((historyObject) =>
            exportData.push({
              timeStamp: object.timeStamp,
              "Total Fare": object.fare,
              vehicleType: historyObject["Vehicle Type"],
              fare: historyObject.fare,
            })
          )
        );

        this.setState({
          dataLoaded: true,
          loader: false,
          tableData: arrayCollection,
          logsData: exportData,
        });
      })
      .catch((err) => {
        this.setState({
          loader:false,
          dataLoaded: false,
        });
        console.log("API DATA ERROR  -->> ", err);
      });
  };

  // method to fetch data
  getData = (
    startDate,
    endDate,
    groupBy,
    currency,
    device,
    countryId,
    cityId,
  ) => {
    this.setState({ condition: 0 });

    fetchRideFareData(
      moment(startDate).unix(),
      moment(endDate).unix(),
      groupBy || 0,
      currency,
      device,
      countryId || "",
      cityId || "",
    )
      .then((response) => {
        this.getTableData(
          this.state.date.startDate,
          this.state.date.endDate,
          parseInt(this.state.selected.value),
          this.state.currencyData,
          this.state.selectedSortByDevice.value,
          countryId,
          cityId,
        );

        let xCategories = dateTimeFormatForGraph(
          this.state.selected.label || "Hour",
          response.data.graph.xaxis.categories
        );

        response.data.graph.xaxis.categories = [...xCategories];
        let isThousandData = response.data.show_message ? true : false;
        let graphData = {
          series: [...response.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "30%" } },
            title: {
              text: "",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },
            chart: {
              width: "90%",
            },
            xaxis: {
              type: "category",
              categories: [...response.data.graph.xaxis.categories],
              title: {
                text: response.data.graph.xaxis.title,
                style: {
                  fontSize: "11px",
                  fontWeight: 700,
                  paddingRight: "20px",
                },
              },

              tickPlacement: "on",
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              padding: {
                left: 40,
              },
            },
            legend: {
              position: "top",
              showForSingleSeries: true,
            },
            yaxis: {
              title: {
                text: response.data.graph.yaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
              labels: {
                offsetX:20,
                show: true,
                style: { fontSize: "11px" },
                formatter: (val, index) => {
                  return tooltipFormatter(val, this.state.currencyData, true);
                },
                // formatter: function (value) {
                //   return parseInt(value);
                // },
                
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              y: {
                formatter: (val, series) => {
                  return tooltipFormatter(val, this.state.currencyData, true);
                },

                title: {
                  formatter: (seriesName) => seriesName,
                },
              },
            },
          },
        };

        this.setState({
          showTable: true,
          statusText: "",
          graphData,
          isMoreThanThousand: isThousandData,
          noData: false,
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((e) => {
        console.log("errrorr------")
        if (e && e.data && e.data.status === 204) {
          this.setState({
            loader: false,
            showSnackbar: true,
            tableData: [],
            noData: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        } else if (
          e.response &&
          e.response.data &&
          e.response.data.message &&
          e.response.data.message === "currency conversion not found"
        ) {
          this.setState({
            loader: false,
            noData: true,
            showTable: false,
            statusText: "Loading data ...",
            snackBarMessage: "currency is not supported",
            showSnackbar: true,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({
              snackBarMessage: "",
              showSnackbar: false,
            });
          }, 1500);
        } else {
          this.setState({
            showTable: false,
            loader: false,
            logsData: [],
            summary: [],
            statusText: "No data for selected date range",
            noData: true,
            snackBarMessage: "No data for selected date range",
            showSnackbar: true,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({
              snackBarMessage: "",
              showSnackbar: false,
            });
          }, 1500);
        }
      });
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    
    this.setState({
      filterData,
    });
  };

  getCurrencyData = (obj) => {
    this.setState({
      currencyData: obj.currencies[0].code,
    });
    this.handleClose();
  };

  goBack = () => {
    this.props.history.push("truck-utilisation");
  };

  // dropDownSelect = (e) => {
  //   this.setState({
  //     storeIdValue: e.target.value,
  //   });
  // };

  sortSelectHandler = (option) => {
    if (option.value === 0 || option.value === 1 || option.value === 2) {
      this.setState({
        selectedSortByDevice: option,
      });
    }
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }
   
      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState({
      date: {
        startDate: startDate,
        endDate: endDate,
      },
      selectData: groupBy,
      selected: selectedGroupBy,
      loader: true,
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
  };

  render() {
    const { classes } = this.props;
    const { country, city } = this.state;
    return (
      <div className="containerDiv reportWrapper" ref={this.myRef}>
        <div className="d-flex pr-0">
          <div className="head w-100">
            <div className="d-flex">
              <div className="w-100">
                <div
                  className="text-grey cursor-pointer globalFontSize"
                  onClick={this.goBack}
                >
                  <i className="fas fa-angle-left mr-2"></i>
                  Reports
                </div>
                <div className="title">{this.state.initialCharName} Revenue</div>
                <div className="d-flex align-items-center text-grey mt-2 globalFontSize">
                  <div className="mr-3 db_ptr">
                    <ReactToPrint
                      trigger={() => (
                        <span>
                          <i className="fas fa-print mr-1"></i>
                          Print
                        </span>
                      )}
                      content={() => this.myRef.current}
                    />
                  </div>
                  <div className="mr-3 db_ptr" onClick={this.toggleModal}>
                    <i className="fas fa-download mr-1"></i>
                    Export
                  </div>
                </div>
                <div
                  className="moreThanThousand"
                  style={{
                    display: this.state.isMoreThanThousand ? "" : "none",
                  }}
                >
                  <div className="iconDiv">
                    <span className="iconSpan">
                      {/* <img className='exportImage' src={SvgIcon} /> */}
                    </span>
                  </div>
                  <div className="bannerContent">
                    This report shows up to 1,000 results. To see all results,
                    you can
                    {/* <button className='buttonExport' onClick={this.fetchExportTableData}>
                           <span>export the report</span>
                        </button> */}
                  </div>
                </div>

                <div className="d-flex flex-column pt-2 w-100">
                  <Filters
                    countries={this.state.countries}
                    cities={this.state.cities}
                    selectHandle={this.handleFilterData}
                    country={country}
                    city={city}
                    handleDateChange={this.handleDateRange}
                    groupByselectData={this.state.selectData}
                    groupByselectHandler={this.selectHandler}
                    groupByselected={this.state.selected}
                  ></Filters>
                </div>

                <div className="d-flex align-items-center justify-content-end mt-2 ml-auto">
                  <div className="d-flex align-items-center">
                    Currency:
                    <div
                      style={{
                        marginLeft: "5px",
                        padding: "2px 18px 2px 12px",
                      }}
                      className="card"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      {this.state.currencyData}
                    </div>
                    <Menu
                      style={{
                        minHeight: "300px",
                        maxHeight: "300px",
                      }}
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                    >
                      <div className="currency-hover-class">
                        <MenuItem>
                          Currency:
                          <Input
                            id=""
                            className="filterCurrency"
                            {...this.state.currencyFilter.input}
                            onChange={(e) => this.filterCurrency(e)}
                          />
                        </MenuItem>
                        {this.state.filterData.map((k, index) => (
                          <MenuItem
                            key={index}
                            className={classes.menuItem}
                            onClick={() => this.getCurrencyData(k)}
                          >
                            <span>
                              <img
                                src={k.flags.svg}
                                height={25}
                                width={25}
                                alt="currency"
                              />
                            </span>
                            <span>^</span>
                            <span className="pl-3 currencySymbol">
                              {k.currencies[0].symbol}
                            </span>
                            <span className="pl-3">{k.name}</span>
                          </MenuItem>
                        ))}
                      </div>
                    </Menu>
                  </div>

                  <div className="d-flex justify-content-end align-items-center ml-3">
                    {/* <StatusIdDropDown
                      storeData={this.state.storeData}
                      storeID={this.state.storeIdValue}
                      dropDownSelect={this.dropDownSelect}
                    /> */}

                    <span
                      className="mr-2 ml-3"
                      // style={{ marginLeft: "20px" }}
                    >
                      Device:
                    </span>

                    <div className="mr-2 globalFontSize">
                      <ReactSelect
                        className={"custom-z-index"}
                        city={this.state.sortByDevice}
                        change={this.sortSelectHandler}
                        selected={this.state.selectedSortByDevice}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between"></div>
          </div>
        </div>
        <div className="body" style={{ top: "-30px" }}>
          {this.state.noData ? (
            <div
              className=" align-items-center justify-content-center"
              style={{
                marginTop: "50px",
                backgroundColor: "white",
                borderRadius: "3px",
                minHeight: "100px",
                boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
              }}
            >
              <h4
                style={{
                  height: "380px",
                  color: "grey",
                  padding: "144px",
                  textAlign: "center",
                }}
              >
                No data for selected date range
              </h4>
            </div>
          ) : (
            <>
              <Paper>
                <Graph
                  overviewData={overviewData}
                  config={this.state.graphData}
                  selected={this.state.selected}
                  loader={this.state.loader}
                  statusText={this.state.statusText}
                  currentRevenue={this.state.currentRevenue}
                  perviousRevenue={this.state.perviousRevenue}
                  revenueGrowthStatus={this.state.revenueGrowthStatus}
                  revenueMessage={this.state.revenueMessage}
                  currencyData={this.state.currencyData}
                  totalSale={this.state.totalSale}
                  revenue={this.state.revenueBoxData}
                  noData={this.state.noData}
                />
              </Paper>
            </>
          )}

          <div
            className=" align-items-center justify-content-center"
            style={{
              marginTop: "50px",
              backgroundColor: "white",
              borderRadius: "3px",
              minHeight: "100px",
              boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
            }}
          >
            {this.state.loader ? (
              <h4
                style={{
                  color: "grey",
                  padding: "30px",
                  textAlign: "center",
                }}
              >
                Loading data...
              </h4>
            ) : this.state.dataLoaded  && this.state.tableData.length ? (
              <Paper className="my-3">
                <Table
                  tableData={this.state.tableData}
                  currencyData={this.state.currencyData}
                />
              </Paper>
            ) : (
              <h4
                style={{
                  color: "grey",
                  padding: "30px",
                  textAlign: "center",
                }}
              >
                No data for selected date range
              </h4>
            )}
          </div>
        </div>
        {/* To Export the Report this modal will open and will confirm either user wants to export the data as csv or not */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>

                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(RideRevenue));
