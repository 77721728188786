import { getForReport } from "../lib/request";
// import moment from "moment";
import jstz from "jstz";
// import axios from "axios";

var timezone = jstz.determine();
let yourTimeZone = timezone.name();

// user session analytics
export const fetchSessionAnalytics = (
  start_time,
  end_time,
  group_by,
  device,
  storeId
) => {
  return getForReport(
    `/python-spark/session/user-logs?timezone=Asia/Calcutta&start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&device=${device}&timezone=${yourTimeZone}&store_categories_id=${storeId}`
  );
};

export const fetchExportSessionTableData = (
  start_time,
  end_time,
  group_by,
  device,
  storeId
) => {
  return getForReport(
    `/python-spark/session/user-logs?timezone=Asia/Calcutta&start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&device=${device}&timezone=${yourTimeZone}&export=1&store_categories_id=${storeId}`
  );
};

export const dashboardTotalOrder = (
  start_time,
  end_time,
  group_by,
  currency,
  storeId
) => {
  return getForReport(
    `/trucker/dashboard/totalOrders?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}`
  );
};

export const dashboardTotalSales = (
  start_time,
  end_time,
  group_by,
  currency,
  storeId
) => {
  return getForReport(
    `/trucker/dashboard/totalSales?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}`
  );
};

// dashboardTopSellingProduct api
export const dashboardTopSellingProduct = (
  start_time,
  end_time,
  group_by,
  currency,
  storeId
) => {
  return getForReport(
    `/python-spark/dashboard/top-product?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}`
  );
};

// dashboardTopWishlistProduct api start_timestamp=1530693339&end_timestamp=1593851743&top=10
export const dashboardTopCartProduct = (start_time, end_time, storeId) => {
  return getForReport(
    `/python-spark/dashboard/top-cart?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&store_categories_id=${storeId}`
  );
};

// dashboardTopCartProduct api start_timestamp=1530693339&end_timestamp=1593851743
export const dashboardTopWishlistProduct = (start_time, end_time, storeId) => {
  return getForReport(
    `/python-spark/dashboard/top-wish?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&top=${5}&store_categories_id=${storeId}`
  );
};

export const fetchEditColumnData = () => {
  return getForReport(`/python-spark/column-name`);
  // return getForReport(`http://13.127.140.33:8091/python-spark/column-name`);
};

// dashboard order average sales data
export const dashboardAverageSales = (
  start_time,
  end_time,
  group_by,
  currency,
  storeId
) => {
  return getForReport(
    `/trucker/dashboard/order/average-sales?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}`
  );
};

// dashboard top-brands data
export const dashboardTopBrands = async (
  start_time,
  end_time,
  group_by,
  currency,
  storeId,
  sort_by
) => {
  return getForReport(
    `/python-spark/dashboard/top-brand?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}&top_brand=5&sort_by=${sort_by}`
  );
};

export const searchFilterWithColName = (colName, string) => {
  return getForReport(
    `/python-spark/column-search?column_name=${colName}&search=${string}`
  );
};

export const dashboardOnlineStoreConversion = (
  start_time,
  end_time,
  storeId
) => {
  return getForReport(
    `/trucker/dashboard/conversion?store_categories_id=${storeId}&start_timestamp=${start_time}&end_timestamp=${end_time}`
  );
};

export const dashboardOrderPayment = (
  start_time,
  end_time,
  group_by,
  currency,
  storeId
) => {
  return getForReport(
    `/trucker/dashboard/order/payment?start_timestamp=${start_time}&end_timestamp=${end_time}&timezone=${yourTimeZone}&group_by=${group_by}&currency=${currency}&store_categories_id=${storeId}`
  );
};

export const getFilteredData = (timeStamp1, timeStamp2, filterObj) => {
  let filteredObj = encodeURIComponent(JSON.stringify(filterObj));
  return getForReport(
    `/python-spark/filter-report?start_timestamp=${timeStamp1}&end_timestamp=${timeStamp2}&filter=${filteredObj}`
  );
  // return getForReport(`/python-spark/filter?filter=${filterObj}`);
};

export const getCurrentReportSubData = (
  startTime,
  endTime,
  groupBy,
  columnArray,
  currency,
  storeId,
  orderBy,
  ascending,
  pageSkip,
  limit
) => {
  if (true) {
    if (columnArray.length === 0) {
      return getForReport(
        `/total-order/filter-report?start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
      );
    } else {
      return getForReport(
        `/total-order/filter-report?start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
      );
    }
  }
  // else if (
  //   moment.unix(endTime).format("MM/DD/YYYY") ===
  //     moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY") &&
  //   moment.unix(startTime).format("MM/DD/YYYY") !== moment.unix(endTime).format("MM/DD/YYYY")
  // ) {
  //   if (columnArray.length === 0) {
  //     return getForReport(
  //       `/total-order/merge-report?start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
  //     );
  //   } else {
  //     return getForReport(
  //       `/total-order/merge-report?start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
  //     );
  //   }
  // } else if (
  //   moment.unix(startTime).format("MM/DD/YYYY") !==
  //     moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY") &&
  //   moment.unix(endTime).format("MM/DD/YYYY") !== moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY")
  // ) {
  //   if (columnArray.length === 0) {
  //     return getForReport(
  //       `/total-order/historical-report?start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
  //     );
  //   } else {
  //     return getForReport(
  //       `/total-order/historical-report?start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
  //     );
  //   }
  // }
};
