import React, { Component } from "react";
import { getFromLocalStorage } from "../../lib/helper";

// scss import
import "./main.scss";

class StoreIdDropDown extends Component {
  render() {
    const { storeData, storeID } = this.props;

    if (getFromLocalStorage("store_id") !== "0") {
      return <div></div>;
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        {<div className="mr-2 globalFontSize">Driver Type:</div>}
        {
          <select
            value={storeID}
            onChange={(e) => this.props.dropDownSelect(e)}
            className="storeId-dropdown"
          >
            {storeData.map((item, index) => (
              <option value={item["id"]} key={`storeId${index}`}>
                {item.name}
              </option>
            ))}
          </select>
        }
      </div>
    );
  }
}

export default StoreIdDropDown;
