import React, { Component } from "react";
import "../../../../static/scss/globalComponent.scss";

import {
  fetchRideCount,
  fetchRideTableCount,
} from "../../../../services/RideCount";
import moment from "moment";
import Snackbar from "../../../../components/snackbar/snackbar";
import LineGraph from "./graph/lineGraph";
import ReactToPrint from "react-to-print";
import Modal from "../../../../components/modal/modal";
import Pbutton from "../../../../components/button/Button";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import StatusIdDropDown from "../../../../components/StatusIdDropdown";
import TableComponent from "./table/table.js";
import "../index.css";

import {
  getCities,
  getCountries,

} from "../../../../services/rideLocationFilter";
import _ from "lodash";
import Filters from "../location/filter";
import { dateTimeFormatForGraph, tooltipFormatter } from "../../../../lib/helper";
import { statusFilter } from "../../../../fixtures/dateFilter/statusFilter";
// import { setInLocalStorage } from "../../../../lib/helper";

class RideCount extends Component {
  // reference of the component
  myRef = React.createRef();
  state = {
    snackBarMessage: "",
    showSnackbar: false,
    tableData: [],
    isExport: false,
    vehicle_type_id: 0,
    isMoreThanThousand: false,
    isDisplayChart: false,
    noDataText: "Loading Data ...",

    storeIdValue: "0",

    loader: true, //loader state
    noData: false,
    dataLoaded: false,
    // loadingScreen: true,
    logsData: [],

    sortByDevice: [
      { label: "All", value: 0 },
      { label: "Android", value: 1 },
      { label: "iOS", value: 2 },
    ],
    selectedSortByDevice: { label: "All", value: 0 },

    initialCharName : window && window._env_ && window._env_.INITIAL_CHART_NAME ?  window._env_.INITIAL_CHART_NAME : "Trucker",
    storeData: statusFilter,

    country: "",
    city: "",
    countryId: "",
    cityId: "",
    countries: [],
    cities: [],

    currencyData: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "INR",
    
  previousCurrencyData: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "INR",
    

  // currencyData: window._env_.CURRENCY,
  // previousCurrencyData: window._env_.CURRENCY,    

    date: {
      startDate:
        localStorage.getItem("startDate") != null
          ? localStorage.getItem("startDate")
          : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        localStorage.getItem("endDate") != null
          ? localStorage.getItem("endDate")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    selected: localStorage.getItem("selectedGroupBy")
      ? JSON.parse(localStorage.getItem("selectedGroupBy"))
      : { value: 0, label: "Hour" },
    selectData: [{ value: 0, label: "Hour" }],
  };

  /**
   * @Author Jai
   * @Date 14 April, 2021
   * @Description Created a Ride Count Chart and added a country, city, zone and device type filter
   */

  componentDidMount() {
    this.handleFilterData("", "initialCall");
    this.intermediate();
  }

  // this function call whenever there is a update in the whole component
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId ||
      prevState.date.startDate !== this.state.date.startDate ||
      prevState.date.endDate !== this.state.date.endDate ||
      prevState.storeIdValue !== this.state.storeIdValue ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      this.state.selected.value !== prevState.selected.value
    ) {
      this.setState({
        isDisplayChart: false,
        noDataText: "Loading Data ...",
      });
      this.intermediate();
    }
    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }

  intermediate = () => {
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.storeIdValue,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  getTableCount = (
    start_timestamp,
    end_timestamp,
    selectedGroupById,
    status,
    device,
    country,
    city
  ) => {
    fetchRideTableCount(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      selectedGroupById || 0,
      status,
      device,
      country,
      city
    )
      .then((res) => {
        let arrayCollection = [];
        let exportData = [];
        let data = {};
        let history = [];

        res.data.data.table.totalAmount.count.map((item, index) => {
          Object.keys(res.data.data.history).map((data, dataIndex) => {
            if (
              this.state.selected.value === 3 ||
              this.state.selected.value === 7
            ) {
              if (
                data ===
                res.data.data.table.totalAmount["bookingDateTimestamp"][index]
              ) {
                history = res.data.data.history[data];
              }
            } else {
              if (index === dataIndex) {
                history = res.data.data.history[data];
              }
            }
            return history;
          });

          data = {
            timeStamp: dateTimeFormatForGraph(
              this.state.selected.label || "Hour",
              res.data.data.table.totalAmount.bookingDateTimestamp
            )[index],
            history,
            count: item,
          };

          return arrayCollection.push(data);
        });
        arrayCollection.map((object) =>
          object.history.map((historyObject) =>
            exportData.push({
              "Date Time": object.timeStamp,
              "Total Count": object.count,
              "Vehicle Type": historyObject["Vehicle Type"],
              count: historyObject.count,
            })
          )
        );
        this.setState({
          dataLoaded: true,
          loader: false,
          tableData: arrayCollection,
          logsData: exportData,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          dataLoaded: false,
          loader: false,
        });
      });
  };

  // This function call the user-activity api and map over the data and sets the chart configuration
  getData = (
    start_timestamp,
    end_timestamp,
    groupBy,
    status,
    selectedSortByDevice,
    countryId,
    cityId,
  ) => {
    fetchRideCount(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      groupBy || 0,
      status,
      selectedSortByDevice,
      countryId || "",
      cityId || "",
    )
      .then((response) => {
        this.getTableCount(
          start_timestamp,
          end_timestamp,
          groupBy || 0,
          status,
          selectedSortByDevice,
          countryId || "",
          cityId || "",
        );

        let xCategories = dateTimeFormatForGraph(
          this.state.selected.label || "Hour",
          response.data.graph.xaxis.categories
        );

        response.data.graph.xaxis.categories = [...xCategories];
        let isThousandData = response.data.show_message ? true : false;

        //graph configuration
        let graphData = {
          series: [...response.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "30%" } },
            title: {
              text: "",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },
            chart: {
              // offsetX: 10,
              // offsetY: 150,
              width: "100%",
            },
            xaxis: {
              type: "category",
              categories: [...response.data.graph.xaxis.categories],
              title: {
                text: response.data.graph.xaxis.title,
                style: {
                  fontSize: "11px",
                  fontWeight: 700,
                  paddingRight: "20px",
                },
              },
              tickPlacement: "on",
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              // providing padding in the graph
              padding: {
                left: 50,
                right: 20,
              },
            },
            legend: {
              position: "top",
              showForSingleSeries: true,
            },
            yaxis: {
              title: {
                text: response.data.graph.yaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
              labels: {
                show: true,
                // offsetX: 20,
                // offsetY: 30,
                style: { fontSize: "11px" },
                formatter: function (value) {
                  return parseInt(value)  === value ? value  : '';
                },
                // formatter: (val, index) => {
                //   return tooltipFormatter(val, this.state.currencyData, false);
                // },
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
            },
          },
        };

        this.setState({
          dataLoaded: true,
          graphData,
          isMoreThanThousand: isThousandData,
          isDisplayChart: true,
          noDataText: "",
        });
      })
      .catch((error) => {
        if (!this.state.date.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            loader: false,
            tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };
  // ========= End of getData function ============ //

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  sortSelectHandler = (option) => {
    if (option.value === 0 || option.value === 1 || option.value === 2) {
      this.setState({
        selectedSortByDevice: option,
      });
    }
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }
      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState(
      {
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        selectData: groupBy,
        selected: selectedGroupBy,
        loader: true,
      }
      // () => this.intermediate()
    );
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
  };

  render() {
    const { country, city } = this.state;
    return (
      <div>
        <div className="containerDiv" ref={this.myRef}>
          <div className="">
            <div
              className="hoverPathClass globalFontSize"
              onClick={() =>
                this.props.history.push("truck-utilisation")
              }
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">{this.state.initialCharName} Count</div>
            <div className="d-flex text-grey mt-2" style={{ marginBottom: "" }}>
              <div className="mr-3 db_ptr">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr"
                onClick={() =>
                  this.setState({ isExport: !this.state.isExport })
                }
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{ display: this.state.isMoreThanThousand ? "" : "none" }}
            >
              <div className="iconDiv">
                <span className="iconSpan"></span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you
                can
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <Filters
                countries={this.state.countries}
                cities={this.state.cities}
                selectHandle={this.handleFilterData}
                country={country}
                city={city}
                handleDateChange={this.handleDateRange}
                groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
              ></Filters>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="d-flex justify-content-end align-items-center ml-auto">
                <StatusIdDropDown
                  storeData={this.state.storeData}
                  storeID={this.state.storeIdValue}
                  dropDownSelect={this.dropDownSelect}
                />
              </div>

              <div className="d-flex justify-content-end align-items-center ml-3">
                <span className="mr-2">Device:</span>

                <div className="mr-2 globalFontSize">
                  <ReactSelect
                    className={"custom-z-index"}
                    city={this.state.sortByDevice}
                    change={this.sortSelectHandler}
                    selected={this.state.selectedSortByDevice}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="activeBarGraph d-flex align-items-center justify-content-center"
            style={{ marginTop: "20px" }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <LineGraph config={this.state.graphData} />
              </div>
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div>

          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "50px",
              backgroundColor: "white",
              borderRadius: "3px",
              minHeight: "100px",
              boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
            }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <TableComponent tableData={this.state.tableData} />
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div>

          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() => this.setState({ isExport: !this.state.isExport })}
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="d-flex justify-content-end">
                  <Pbutton
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    data={this.state.logsData}
                    filename={"my-file.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(RideCount);
