import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";

/**
 * @Author Jai
 * @Date 8 April, 2021
 * @Description Created this graph page for getting the status of Ride Revenue and we are using this page on Ride Analytics Overview.
 */

class averageSaleOverview extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
          {this.props.label}
          </span>
          <span className="link globalFontSize fontweightone">
            {/* <Link url={this.props.url}>View Activity</Link> */}
            {/* <Link>View Activity</Link> */}
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.averageSale ? (
              `${getSymbolFromCurrency(this.props.currency)} ${
                // this.props.averageSale
                Number(this.props.averageSale.toFixed(2))
              }`
            ) : (
              `${getSymbolFromCurrency(this.props.currency)} ` + 0
            )}
          </span>
        </div>
        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default averageSaleOverview;
