//library import
import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "../../../components/InputBox/Input";
//custom import
import ReactSelect from "../../../components/reactSelect/reactSelect";
import { storeIdApiCall } from "../../../services/storeId";
import { withStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import { dateFilter } from "../../../fixtures/dateFilter/dateFIlter";
import { overviewData } from "../../../fixtures/dummyApi/dummyApi";
import DateRangePicker from "../../../components/dateRangePicker/dateRangePicker";
import DatePicker from "../../../components/DatePicker";

import { countries } from "../../../fixtures/dummyApi/countries";
//scss
import "./Reports.scss";
import Modal from "../../../components/modal/modal";
import {
  getCurrentReportSubData,
  fetchEditColumnData,
  searchFilterWithColName,
  getFilteredData,
  fetchReportDescriptiveData,
  totalRevenueDataBox,
} from "../../../services/reportSubData";
import { withRouter } from "react-router-dom";
// import ReactToPrint from "react-to-print";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import Snackbar from "../../../components/snackbar/snackbar";
import Graph from "./graph";
import Table from "./table";

// import Header from './header'
import { config } from "./graph/graphConfig";
import async from "async";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import {dateTimeFormatForGraph, getFromLocalStorage, setInLocalStorage, tooltipFormatter} from "../../../lib/helper"

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class TotalSale extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      date: {
        //start date and end date
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      revenueDate: {
        //start date and end date
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      storedIndex: "",
      loader: true, //loader state
      selected:
        localStorage.getItem("selectedGroupBy") != null
          ? JSON.parse(localStorage.getItem("selectedGroupBy"))
          : { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],
      config: config,

      logsData: [], //table data
      columns: [], //table columns

      skip: 0, //starting point
      limit: 50, //ending point
      page: 1, //pagination page
      dataToShow: 50, //50 or 100 data to show
      //  totalData: Number,                              // total no of data

      graph: {},

      summary: {},
      anchorEl: null,
      editColumnData: [],

      noData: false,
      modal: false,
      groupedOptions: [],
      graphSeries: [],
      revenueBoxData: {},
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "In", value: 1 },
            { label: "NotIn", value: 0 },
          ],
          isIsNotselected: [{ label: "In", value: 1 }],

          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
      seriesToRender: [],
      filterWindow: false,
      checklistDisplay: false,
      columnArray: [15, 16, 18, 19, 21, 22, 23, 25, 26, 27, 28],
      copyOfGroupedCols: [],
      showSnackbar: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      snackBarMessage: "",
      keysArr: [],
      currencyData: getFromLocalStorage("currency"),
      previousCurrencyData: getFromLocalStorage("currency"),
      totalGroupBy: [
        { value: 0, label: "Hour" },
        { value: 1, label: "Day" },
        { value: 2, label: "Week" },
        { value: 3, label: "Month" },
        { value: 4, label: "Quarter" },
        // { value: 5, label: "Year" },
        { value: 6, label: "Hour Of Day" },
        { value: 7, label: "Day Of Week" },
      ],
      statusText: "",
      currentRevenue: 0,
      perviousRevenue: 0,
      revenueGrowthStatus: 0,
      revenueMessage: "",
      prevStateSelectedGroupBy: "Hour",
      filterData: [...countries],
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoaded: false,
      condition: 0,
      showTable: false,
      hoverState: false,
      dataLoadedstoreId: false,
    };
  }

  searchHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    for (let i in option) {
      if (option[i].label !== "N/A") {
        option[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option[i].value;
      }
    }

    filterArray[index].selectedOption = option;
    let arr = [];
    if (option && option.length > 0) {
      // option.map(k => arr.push(k.label))
      option.map((k) => {
        let b = "";
        if (k.label.includes("Is not")) {
          b = k.label.substr(7, k.label.length - 1);
        } else {
          b = k.label.substr(3, k.label.length - 1);
        }
        return arr.push(b);
      });
    }
    this.setState({
      storedIndex: filterArray[index].groupedOptionsSelected.index,
    });
    filter[index].parameter_value = arr;
    this.setState({ filterArray, filterSendToApi: filter }, () => {});
  };

  createData = (obj) => {
    let a = [];
    let arr = Object.keys(obj[0]).map((k) => a.push(k));
    return arr;
  };

  // this function takes index of the box and values (text)
  changeOption = (index, value) => {
    value = index;
    index = this.state.filterArray.length - 1;
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    let cols = [...this.state.groupedOptions];

    let valSeached = [];
    // if(value) {
    if (value.length > 3) {
      searchFilterWithColName(
        filterArray[index].groupedOptionsSelected.index,
        value
      )
        .then((data) => {
          if (
            this.state.storedIndex !==
            filterArray[index].groupedOptionsSelected.index
          ) {
            filterArray[index].selectedOption = [];
            this.setState({ filterArray });
          }

          let optionsArr = [];
          Object.keys(data.data.data.search_result).map((k) =>
            data.data.data.search_result[k].map((x) =>
              optionsArr.push({ label: x, value: x })
            )
          );
          filterArray[index].searchOption = optionsArr;
          Object.keys(data.data.data.search_result).map((k) =>
            valSeached.push(...data.data.data.search_result[k])
          );
          this.setState({ filterArray });
          let indexToDelete;
          for (let i in cols)
            for (let j in cols[i].options)
              if (
                cols[i].options[j].index ===
                filterArray[index].groupedOptionsSelected.index
              )
                indexToDelete = { mainIndex: i, subIndex: j };

          cols[indexToDelete.mainIndex].options.splice(
            indexToDelete.subIndex,
            1
          );
          this.setState({ groupedOptions: cols });
        })
        .catch((err) => console.log("{changeOption err}", err));
    }
    // }
    this.setState({ filterSendToApi: filter });
  };

  checklistHandler = (event) => {
    let a = [...this.state.columnArray];
    if (a.includes(event)) {
      let index = a.indexOf(event);
      a.splice(index, 1);
    } else {
      a.splice(1, 0, event);
    }

    this.setState({ checklistDisplay: false, columnArray: a }, () => {
      this.getData(
        this.state.date.startDate,
        this.state.date.endDate,
        parseInt(this.state.selected.value),
        a,
        this.state.currencyData,
        this.state.storeIdValue
      );
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true }, () =>
      this.intermediate()
    );
  };

  tableDateRender = (date) => {
    let format = {
      Hour: "ll H:00",
      Day: "ll",
      Week: "ll",
      // "Hour Of Day": "HH:00",
      Year: "ll",
    };
    if (Object.keys(format).includes(this.state.selected.label)) {
      return moment(date).format(format[this.state.selected.label]);
    } else {
      return date;
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  returnDataWithTwoDecsOrInteger = (n) => {
    if (Number(n) === n && n % 1 === 0) {
      return n;
    } else if (Number(n) === n && n % 1 !== 0) {
      return parseFloat(n.toFixed(2));
    } else if (String(n)) {
      return n;
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState(
      {
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        selectData: groupBy,
        selected: selectedGroupBy,
        revenueDate: {
          startDate: startDate,
          endDate: endDate,
        },

        loader: true,
      },
      // () => this.intermediate()
    );
  };

  handleRevenueDateRange = (...arges) => {
    let startDate =
      arges[0].length !== 0 ? arges[0][0].format("YYYY-MM-DD HH:mm:ss") : null;
    let endDate =
      arges[0].length !== 0 ? arges[0][1].format("YYYY-MM-DD HH:mm:ss") : null;
    this.setState(
      {
        revenueDate: {
          startDate: startDate,
          endDate: endDate,
        },
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        loader: true,
      },
      () => this.intermediate()
    );
  };

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    let newPage;

    if (newVal === 100) {
      newPage = Math.trunc(
        stateCopy.page % 2 === 0 ? stateCopy.page / 2 : stateCopy.page / 2 + 1
      );
    } else {
      newPage = stateCopy.page * 2 - 1;
    }

    stateCopy.page = newPage;
    stateCopy.skip = stateCopy.page * newVal - newVal;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  //get new data
  componentDidUpdate = (prevProps, prevState) => {
    //

    //old params and new params are diff than get new data
    if (
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow ||
      this.state.currencyData !== prevState.currencyData ||
      this.state.storeIdValue !== prevState.storeIdValue ||
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate !== prevState.date.endDate
    ) {
      this.intermediate();
    }
    if (
      this.state.revenueDate !== prevState.revenueDate ||
      this.state.storeIdValue !== prevState.storeIdValue
    ) {
      this.revenueBox(
        this.state.revenueDate.startDate,
        this.state.revenueDate.endDate,
        this.state.storeIdValue
      );
    }
  };

  revenueBox = (startDate, endDate, storeId) => {
    totalRevenueDataBox(startDate, endDate, storeId)
      .then((res) => {
        this.setState({
          revenueBoxData: res.data,
          // loader: false
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log("res revenue box error", err);
      });
  };

  intermediate = () => {
    this.setState({
      loader: true,
      logsData: [],
      summary: [],
      config: config,
      statusText: "Loading data ...",
    });
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.columnArray,
      this.state.currencyData,
      this.state.storeIdValue
      //  this.state.selectedCity.value
    );
  };

  //get initial data on didmount
  componentDidMount = () => {
    this.getAllStoreId();
    this.intermediate()
    // this.getData(
    //   this.state.date.startDate,
    //   this.state.date.endDate,
    //   parseInt(this.state.selected.value),
    //   this.state.columnArray,
    //   this.state.currencyData,
    //   this.state.storeIdValue
    //   //  this.state.selectedCity.value
    // );
    // this.revenueBox(
    //   this.state.revenueDate.startDate,
    //   this.state.revenueDate.endDate,
    //   this.state.storeIdValue
    // );
    async.parallel(
      {
        fetchEditColumn: (callback) => {
          fetchEditColumnData()
            .then((data) => {
              let anotherObj = [];
              Object.keys(data.data.columns).map((k, i) =>
                Object.keys(data.data.columns[k]).map((u, w) => {
                  anotherObj.push({
                    label: u,
                    options: [{ index: "", value: "" }],
                  });
                  anotherObj[k]["options"] = data.data.columns[k][u].map(
                    (x) => {
                      return { label: x.value, value: x.value, index: x.index };
                    }
                  );
                })
              );
              this.setState({
                editColumnData: data.data.columns,
                copyOfGroupedCols: anotherObj,
              });
            })
            .catch((error) => callback(error, null));
        },
        fetchTotalSaleDiscruptive: (callback) => {
          fetchReportDescriptiveData()
            .then((response) => {
              if (response) {
                this.setState({
                  currentRevenue: response.data.current_period,
                  perviousRevenue: response.data.previous_period,
                  revenueGrowthStatus: response.data.percentage,
                  revenueMessage: response.data.data,
                });
              }
            })
            .catch((error) => callback(error, null));
        },
      },
      (err, data) => console.log("Error is occured", err)
    );
  };

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  closeFilterBox = () => {
    this.setState({
      filterWindow: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  applyFilter = () => {
    getFilteredData(
      moment(this.state.date.startDate).unix(),
      moment(this.state.date.endDate).unix(),
      this.state.filterSendToApi
    )
      .then((data) => {
        this.methodToPopulateDataAfterFilter(data.data.data);
      })
      .catch((err) => {
        this.setState({
          showSnackbar: true,
          snackBarMessage: "No data found for selected filter.",
        });
        setTimeout(() => {
          this.setState({ showSnackbar: false });
        }, 2000);
      });
    this.setState({
      filterWindow: false,
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  // this method will copy the data which i have received with filtered API,
  // and display it on table and graph
  methodToPopulateDataAfterFilter = (bigObject) => {
    let mgraph = bigObject.graph;
    let mxcat;
    let columns = Object.keys(bigObject.table[0]); // column name from api
    let filterColumns = this.state.editColumnData.map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    let anotherObj = [];

    Object.keys(this.state.editColumnData).map((k, i) =>
      Object.keys(this.state.editColumnData[k]).map((u, w) => {
        anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
        anotherObj[k]["options"] = this.state.editColumnData[k][u].map((x) => {
          return { label: x.value, value: x.value, index: x.index };
        });
      })
    );

    this.setState({ groupedOptions: anotherObj });
    const groupedOptionsSelected = filterColumns[0];
    let cold = columns.map((item, index) => {
      // modified according to table structure
      return {
        Header: item[0].toUpperCase() + item.slice(1),
        accessor: (data) => {
          if (
            item.toLowerCase().includes("date") &&
            this.state.selected.value < 3
          )
            return moment(Object.values(data)[index]).format("DD-MMMM-YYYY LT");
          else if (item.toLowerCase().includes("orders"))
            return Object.values(data)[index];
          else {
            let type = typeof Object.values(data)[index];
            if (type === "number")
              return (
                Object.values(data)[index] &&
                Object.values(data)[index].toFixed(2)
              );
            else return Object.values(data)[index];
          }
        },
        id: item,
        show: true,
        width: 150,
      };
    });

    if (this.state.selected.value < 3)
      mxcat = mgraph.xcat.map((item) => moment(item).format("DD-MMMM-YYYY LT"));
    else mxcat = mgraph.xcat.map((item) => item);

    mgraph.xcat = mxcat;

    let obj = bigObject.summary;
    obj["Summary"] = "Summary";
    // let x = bigObject.table.unshift(obj);
    // bigObject.table.unhsift(bigObject.summary);
    let getOnlyOrders = bigObject.table.map((k) => k.order);

    this.setState({
      seriesToRender: this.createNewArrayToRender(
        mgraph.series[0].data,
        getOnlyOrders
      ),
      columns: cold,
      logsData: bigObject.table,
      graph: mgraph,
      summary: bigObject.summary,
      // groupedOptions: anotherObj,
      groupedOptionsSelected,
      loader: false,
      noData: false,
    });
  };

  //method to fetch data
  getData = (
    startDate,
    endDate,
    sort_by,
    columnArray,
    currencyData,
    storeIdValue
  ) => {
    setInLocalStorage("currency", currencyData);
    this.setState({ condition: 0 });
    getCurrentReportSubData(
      moment(startDate).unix(),
      moment(endDate).unix(),
      sort_by,
      columnArray,
      currencyData,
      storeIdValue
    )
      .then((data) => {
        let newConfig = { ...this.state.config };
        let series = data.data.data.graph.series;
        newConfig.series = series;

        let columns = Object.keys(data.data.data.table[0]); // column name from api
        let filterColumns = this.state.editColumnData.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        let anotherObj = [];

        Object.keys(this.state.editColumnData).map((k, i) =>
          Object.keys(this.state.editColumnData[k]).map((u, w) => {
            anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
            anotherObj[k]["options"] = this.state.editColumnData[k][u].map(
              (x) => {
                return { label: x.value, value: x.value, index: x.index };
              }
            );
          })
        );

        const groupedOptionsSelected = filterColumns[0];
        columns[0] = this.state.selected.label;
        let cold = columns.map((item, index) => {
          // modified according to table structure
          return {
            Header: item[0].toUpperCase() + item.slice(1),
            accessor: (data) => {
              if (
                item.toLowerCase().includes("date") &&
                this.state.selected.value < 1
              )
                return moment(Object.values(data)[index]).format(
                  "DD-MMMM-YYYY LT"
                );
              else if (item.toLowerCase().includes("orders"))
                return Object.values(data)[index];
              else {
                let type = typeof Object.values(data)[index];
                if (type === "number")
                  return (
                    Object.values(data)[index] &&
                    Object.values(data)[index].toFixed(2)
                  );
                else return Object.values(data)[index];
              }
            },
            id: item,
            show: true,
            width: 150,
          };
        });

        let format = {
          Hour: "ll H:00",
          Day: "ll",
          Week: "ll",
          "Hour Of Day": "HH:00",
        };
        let xCategories = [];
        if (
          data &&
          data.data &&
          data.data.data &&
          data.data.data.graph &&
          data.data.data.graph.xcat
        ) {
          xCategories = dateTimeFormatForGraph(
            this.state.selected.label || "Hour",
            data.data.data.graph.xcat
          );
        }

        if (this.state.selected.label === "Hour Of Day") {
          data.data.data.table = data.data.data.table.map((tableObject) => {
            const date = moment(tableObject.dateTime).format(
              format[this.state.selected.label]
            );
            tableObject.dateTime = date;
            return tableObject;
          });
        }

        let columnWidth = xCategories.length <= 7 ? "30%" : "100%";
        newConfig.options = {
          ...newConfig.options,
          xaxis: {
            ...newConfig.options.xaxis,
            categories: xCategories,
            title: {
              text: this.state.selected.label,

              style: {
                fontSize: "11px",
              },
            },
          },
          grid: {
            padding: {
              left: 40,
            },
          },
          yaxis: {
            ...newConfig.options.yaxis,
            title: {
              text: data.data.data.graph.series[0].name,
              // offsetX: 100,
            },
            labels: {
              offsetX:20,
              formatter: (val, index) => {
                return tooltipFormatter(val, this.state.currencyData, false);
              },
            },
          },
          plotOptions: {
            ...config.options.plotOptions,
            bar: {
              horizontal: false,
              columnWidth: columnWidth,
            },
          },
          tooltip: {
            enabled: true,
            enabledOnSeries: true,
            y: {
              formatter: (val, series) => {
                return tooltipFormatter(val, this.state.currencyData, false);
              },
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
            followCursor: true,
          },
        };
        this.setState({
          columns: cold,
          showTable: true,
          logsData: data.data.data.table,
          statusText: "",
          config: newConfig,
          summary: data.data.data.summary,
          groupedOptionsSelected,
          groupedOptions: anotherObj,
          loader: false,
          noData: false,
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.message &&
          e.response.data.message === "currency conversion not found"
        ) {
          this.setState({
            loader: false,
            noData: false,
            showTable: false,
            statusText: "Loading data ...",
            snackBarMessage: "currency is not supported",
            showSnackbar: true,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        } else {
          this.setState({
            config: config,
            showTable: false,
            loader: false,
            logsData: [],
            summary: [],
            statusText: "No data for selected date range",
            noData: false,
            snackBarMessage: "No data for selected date range",
            showSnackbar: true,
            currencyData: this.state.previousCurrencyData,
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        }
      });
  };

  createNewArrayToRender = (arr, anotherArray) => {
    let arrayToPush = [];
    let object = {};
    arr.map((k, i) => {
      return (
        (object = {}),
        (object["y"] = k),
        (object["Order"] = anotherArray[i]),
        arrayToPush.push(object)
      );
    });
    return arrayToPush;
  };

  addFilter = () => {
    let stateObj = { ...this.state };
    let filterArray = [...stateObj.filterArray];
    let filterObj = [...stateObj.filterSendToApi];
    filterObj.push({
      column: "",
      condition_operator: 1,
      parameter_value: [],
    });
    filterArray.push({
      isIsNotData: [
        { value: "Is", label: "Is" },
        { value: "IsNot", label: "Is not" },
      ],
      isIsNotselected: [{ value: "Is", label: "Is" }],
      searchInput: "",
      selectedOption: [],
      searchOption: [{ value: "", label: "N/A" }],
    });

    this.setState({ filterArray, filterSendToApi: filterObj });
  };

  getCurrencyData = (obj) => {
    this.setState({
      currencyData: obj.currencies[0].code,
    });
    this.handleClose();
  };

  removeFilter = (index) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];

    filter.splice(index, 1);
    filterArray.splice(index, 1);

    this.setState({ filterArray, filterSendToApi: filter });
  };

  changeHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].isIsNotselected = [option];
    filter[index].condition_operator = option.label === "In" ? 1 : 0;
    let option2 = filterArray[index].selectedOption;

    for (let i in option2) {
      if (option2[i].label !== "N/A") {
        option2[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option2[i].value;
      }
    }

    filterArray[index].selectedOption = option2;

    this.setState({ filterArray, filterSendToApi: filter });
  };

  goBack = () => {
    this.props.history.push("truck-utilisation");
    // this.props.changePage();
  };

  groupedOptionsSelectedHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let cols = [...this.state.groupedOptions];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].groupedOptionsSelected = option;
    filter[index].column = option.index;
    searchFilterWithColName(option.index, "").then((data) => {
      if (this.state.storedIndex !== option.index) {
        filterArray[index].selectedOption = [];
        if (
          this.state.saveIndexOfDeletedObject.mainIndex &&
          this.state.saveObjectOfDeletedObject
        ) {
          cols[this.state.saveIndexOfDeletedObject.mainIndex].options.push(
            this.state.saveObjectOfDeletedObject
          );
        }
        this.setState({ filterArray, groupedOptions: cols });
      }

      let optionsArr = [];
      Object.keys(data.data.data.search_result).map((k) =>
        data.data.data.search_result[k].map((x) =>
          optionsArr.push({ label: x, value: x })
        )
      );
      filterArray[index].searchOption = optionsArr;
      let indexToDelete;
      let objectOfIndexWhichIsDeleted;
      for (let i in cols)
        for (let j in cols[i].options)
          if (
            cols[i].options[j].index ===
            filterArray[index].groupedOptionsSelected.index
          ) {
            indexToDelete = { mainIndex: i, subIndex: j };
            objectOfIndexWhichIsDeleted = cols[i].options[j];
          }
      cols[indexToDelete.mainIndex].options.splice(indexToDelete.subIndex, 1);
      this.setState({
        groupedOptions: cols,
        filterArray,
        saveIndexOfDeletedObject: indexToDelete,
        saveObjectOfDeletedObject: objectOfIndexWhichIsDeleted,
      });
    });

    this.setState({ filterSendToApi: filter });
  };

  // save button
  openNewUrl = () => {
    const url = window.location.href;
    localStorage.setItem("columns", JSON.stringify(this.state.columnArray));
    localStorage.setItem(
      "startDate",
      JSON.stringify(this.state.date.startDate)
    );
    localStorage.setItem("endDate", JSON.stringify(this.state.date.endDate));
    localStorage.setItem(
      "sortBy",
      JSON.stringify(parseInt(this.state.selected.value))
    );
    window.open(url, "_blank");
  };

  changeFilterWindowState = () => {
    this.setState({
      filterWindow: true,
    });
  };

  isCheckListDisplay = () => {
    this.setState({
      checklistDisplay: !this.state.checklistDisplay,
    });
  };

  currencyFormat = (value) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      return Number(kValue.toFixed(2)) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;
      return Number(kValue.toFixed(2)) + " M";
    }
    return Number(value.toFixed(2));
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase())) !== -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    
    this.setState({
      filterData,
    });
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="reportWrapper" ref={this.myRef}>
        <div style={{ display: "flex", paddingRight: "0" }}>
          <div className="head w-100">
            <div className="d-flex">
              <div className="w-100">
                <div
                  className="text-grey cursor-pointer globalFontSize"
                  onClick={this.goBack}
                >
                  <i className="fas fa-angle-left mr-2"></i>Reports
                </div>
                <div className="title">Sales Over Time</div>
                <div className="d-flex align-items-center text-grey mt-2 globalFontSize">
                  <div className="mr-3 db_ptr">
                    <ReactToPrint
                      trigger={() => (
                        <span>
                          <i className="fas fa-print mr-1"></i>Print
                        </span>
                      )}
                      content={() => this.myRef.current}
                    />
                  </div>
                  <div className="mr-3 db_ptr" onClick={this.toggleModal}>
                    <i className="fas fa-download mr-1"></i>Export
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="mr-2 globalFontSize">Date Range: </span>
                  <div
                    className="mt-2 rangePickr mb-2"
                    style={{ width: "200px" }}
                  >
                    <DatePicker
                      ranges={{ ...dateFilter }}
                      value={[
                        this.state.date.startDate,
                        this.state.date.endDate,
                      ]}
                      getGroupBy={true}
                      handleDateChange={this.handleDateRange}
                    />
                  </div>

                  <span
                    className="mr-2 globalFontSize"
                    style={{ marginLeft: "20px" }}
                  >
                    Group By:
                  </span>
                  <div className="selectBody">
                    <ReactSelect
                      city={this.state.selectData}
                      change={this.selectHandler}
                      selected={this.state.selected}
                    />
                  </div>

                  <div
                    className="globalFontSize"
                    style={{ marginLeft: "20px" }}
                  >
                    Currency:
                    <Button
                      className={classes.selectCountryButton}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      {this.state.currencyData}
                    </Button>
                    <Menu
                      style={{ minHeight: "300px", maxHeight: "300px" }}
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                    >
                      <div className="currency-hover-class">
                        <MenuItem>
                          Currency:
                          <Input
                            id=""
                            className="filterCurrency"
                            {...this.state.currencyFilter.input}
                            onChange={(e) => this.filterCurrency(e)}
                          ></Input>
                        </MenuItem>
                        {this.state.filterData.map((k, index) => (
                          <MenuItem
                            key={index}
                            className={classes.menuItem}
                            onClick={() => this.getCurrencyData(k)}
                          >
                            <span>
                              <img
                                src={k.flags.svg}
                                height={25}
                                width={25}
                                alt="currency"
                              />
                            </span>
                            {/* <span>^</span> */}
                            <span className="pl-3 currencySymbol">
                              {k.currencies[0].symbol}
                            </span>
                            <span className="pl-3">{k.name}</span>
                          </MenuItem>
                        ))}
                      </div>
                    </Menu>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "10px" }}
                >
                  <div
                    className="d-flex align-items-center globalFontSize"
                    style={{ marginLeft: "0px" }}
                  >
                    <StoreIdDropDown
                      storeData={this.state.storeData}
                      storeID={this.state.storeIdValue}
                      dropDownSelect={this.dropDownSelect}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="mr-2 globalFontSize">
                      Date Range for Revenue:
                    </span>
                    <div
                      className="mt-2 rangePickr mb-2"
                      style={{ width: "200px" }}
                    >
                      <DateRangePicker
                        // onChange={this.onDateChange}
                        ranges={{ ...dateFilter }}
                        value={[
                          this.state.revenueDate.startDate,
                          this.state.revenueDate.endDate,
                        ]}
                        onChange={this.handleRevenueDateRange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between"></div>
          </div>
        </div>
        <div className="body" style={{ top: "-70px" }}>
          {this.state.noData ? (
            <div className="noData">
              <div>No Data for selected Date</div>
            </div>
          ) : (
            <div>
              <Graph
                overviewData={overviewData}
                config={this.state.config}
                selected={this.state.selected}
                loader={this.state.loader}
                statusText={this.state.statusText}
                currentRevenue={this.state.currentRevenue}
                perviousRevenue={this.state.perviousRevenue}
                revenueGrowthStatus={this.state.revenueGrowthStatus}
                revenueMessage={this.state.revenueMessage}
                currencyData={this.state.currencyData}
                revenue={this.state.revenueBoxData}
                noData={this.state.noData}
              />
              <Table
                loader={this.state.loader}
                showTable={this.state.showTable}
                changeFilterWindowState={(e) => this.changeFilterWindowState(e)}
                isCheckListDisplay={() => this.isCheckListDisplay()}
                checklistDisplay={this.state.checklistDisplay}
                editColumnData={this.state.editColumnData}
                checklistHandler={(index) => this.checklistHandler(index)}
                columnArray={this.state.columnArray}
                filterWindow={this.state.filterWindow}
                filterArray={this.state.filterArray}
                columns={this.state.columns}
                summary={this.state.summary}
                logsData={this.state.logsData}
                page={this.state.page}
                changeOption={this.changeOption}
                groupedOptions={this.state.groupedOptions}
                closeFilterBox={this.closeFilterBox}
                groupedOptionsSelectedHandler={
                  this.groupedOptionsSelectedHandler
                }
                changeHandler={this.changeHandler}
                removeFilter={(index) => this.removeFilter(index)}
                searchHandler={this.searchHandler}
                searchOption={(index) => this.searchOption(index)}
                addFilter={this.addFilter}
                // closeFilterBox={this.closeFilterBox}
                applyFilter={this.applyFilter}
                changeActivePage={this.changeActivePage}
                returnDataWithTwoDecsOrInteger={(index) =>
                  this.returnDataWithTwoDecsOrInteger(index)
                }
                dataToShowHandler={this.dataToShowHandler}
                dateRender={(data) => this.tableDateRender(data)}
                dataToShow={this.state.dataToShow}
              />
            </div>
          )}
        </div>
        {/* To Export the Report this modal will open and will confirm either user wants to export the data as csv or not */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>

                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(TotalSale));
