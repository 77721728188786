/** @format */

import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class userDonutGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      sortByDevice: 3,
      redirect: false,
    };
  }

  render() {
    return (
      <div className="overviewBody pt-2 d-flex m-auto">
        <ReactApexChart
          options={this.props.config.options}
          series={this.props.config.series}
          type="donut"
          height={480}
          width={480}
        />
      </div>
    );
  }
}

export default userDonutGraph;
