import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./dateRangePicker.scss";


const { RangePicker } = DatePicker;

class DateRangePicker extends React.Component {

  disabledDate = (current) => {
    // Can not select days before today and today 
    return current && current > moment().endOf('day');

  }

  render() {
    return (
      <>
      <div>
        <RangePicker
          ranges={this.props.ranges}
          value={[this.props.value[0] ? moment(this.props.value[0]) : null, this.props.value[1] ? moment(this.props.value[1]) : null]}
          onChange={this.props.onChange}
          allowClear={false}
          disabledDate={this.disabledDate}
          size="small"
        />
      </div>
      <style>
                {
                  `
                  .ant-calendar-range-left .ant-calendar-my-select::before{
                    content: "Start Date : ";
                    font-weight: 500;
                    line-height: 40px;
                    color:rgba(0, 0, 0, 0.85);
                  }
                  .ant-calendar-range-right .ant-calendar-my-select::before{
                    content: "End Date : ";
                    font-weight: 500;
                    line-height: 40px;
                    color:rgba(0, 0, 0, 0.85);
                  }
                 `
                }
       </style>
      </>
    );
  }
}
export default DateRangePicker;
