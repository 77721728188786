import React, { Component } from "react";
import { dateFilter } from "../../../../fixtures/dateFilter/dateFIlter";
import DateRangePicker from "../../../../components/dateRangePicker/dateRangePicker";
import moment from "moment";
import StoreIdDropDown from "../../../../components/StoreIdDropdown";
import { fetchTransportAcceptenceData } from "../../../../services/transporteracceptance";

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      driverId: "0",
      storeData: [{ 'id': 0, name: 'Company' }, {'id':1, name:'Independent'}],
      selectedGroupByDevice: { value: 0, label: "All Devices" },
      selectedGroupByDeviceList: [
        { value: 0, label: "All Devices" },
        { value: 1, label: "Android" },
        { value: 2, label: "iOS" },
        { value: 3, label: "Web" },
      ],
      selectedGroupByPeriod: { value: "0", label: "Hour" },
      selectGroupByPeriodList: [{ value: 0, label: "Hour" }],
      totalGroupBy: [
        { value: 0, label: "Hour" },
        { value: 1, label: "Day" },
        { value: 2, label: "Week" },
        { value: 3, label: "Month" },
        { value: 4, label: "Quarter" },
        // { value: 5, label: "Year" },
        { value: 6, label: "Hour Of Day" },
        { value: 7, label: "Day Of Week" },
      ],
      startDate: props.startDate,
      endDate: props.endDate,
      groupBy: props.groupBy,
    };
  }

  selectGroupByDeviceHandler = (option) => {
    this.setState({
      selectedGroupByDevice: option,
    });
    this.props.setInParentComponent("device", option);
  };

  checkGroupByExistsOrNot = (listOfGroupBy) => {
    if (!listOfGroupBy.includes(this.state.selectedGroupByPeriod)) {
      this.setState({ selectedGroupByPeriod: listOfGroupBy[0] });
      this.props.setInParentComponent("groupBy", listOfGroupBy[0]);
    }
  };
  changeGroupByPeriodList = (numberOfDays) => {
    let listOfGroupBy = [];
    if (numberOfDays === 0) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      this.setState({ selectGroupByPeriodList: listOfGroupBy });
      this.setState({ groupBy: listOfGroupBy[0] });
      this.props.setInParentComponent("groupBy", listOfGroupBy[0]);
    } else if (numberOfDays > 0 && numberOfDays <= 7) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      this.setState({ selectGroupByPeriodList: listOfGroupBy });
    } else if (numberOfDays > 7 && numberOfDays <= 30) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[2]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      listOfGroupBy.push(this.state.totalGroupBy[6]);
      this.setState({ selectGroupByPeriodList: listOfGroupBy });
    } else if (numberOfDays > 30 && numberOfDays <= 90) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[2]);
      listOfGroupBy.push(this.state.totalGroupBy[3]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      listOfGroupBy.push(this.state.totalGroupBy[6]);
      this.setState({ selectGroupByPeriodList: listOfGroupBy });
    } else {
      this.setState({ selectGroupByPeriodList: this.state.totalGroupBy });
      return this.checkGroupByExistsOrNot(this.state.totalGroupBy);
    }
    return this.checkGroupByExistsOrNot(listOfGroupBy);
  };

  selectGroupByPeriodHandler = (...arges) => {
    let startDate =
      arges[0].length !== 0 ? arges[0][0].format("YYYY-MM-DD HH:mm:ss") : null;
    let endDate =
      arges[0].length !== 0 ? arges[0][1].format("YYYY-MM-DD HH:mm:ss") : null;

    let startDatefordiff = moment(startDate).startOf("day");
    let endDatefordiff =
      moment().diff(endDate, "H") === 0 || moment().diff(endDate, "H") === "0"
        ? moment()
        : moment(endDate).endOf("day");
    let numberOfDays = endDatefordiff.diff(startDatefordiff, "days");
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      () =>
        this.props.setInParentComponent("date", {
          startDate: startDate,
          endDate: endDate,
        })
    );
    return this.changeGroupByPeriodList(numberOfDays);
  };

  selectGroupByHandler = (option) => {
    this.setState({ groupBy: option });
    this.props.setInParentComponent("groupBy", option);
  };

  // driverIdSelect = (e) => {
  //   this.setState({
  //     driverId: e.target.value,
  //   },
  //   ()=>fetchTransportAcceptenceData(this.props.startDate, this.props.endDate, this.props.storeIdValue, this.state.driverId));
  // };
  render() {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="mr-2 globalFontSize">Date Range: </span>
          <div className="mt-2 rangePickr mb-2">
            <DateRangePicker
              ranges={{ ...dateFilter }}
              value={[this.props.startDate || null, this.props.endDate || null]}
              onChange={this.selectGroupByPeriodHandler}
            />
          </div>
          {/* <div>
          <StoreIdDropDown
          storeData={this.state.storeData}
            storeID={this.state.storeID}
            dropDownSelect={this.driverIdSelect}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default index;
