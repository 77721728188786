// Dependency Imports
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
//icons
import PollIcon from "@material-ui/icons/Poll";
//helper function
import {
  Route,
  withRouter,
  // BrowserRouter as Router,
  // Redirect,
} from "react-router-dom";

//nested routes
import RideCount from "./RideAnalytics/RideCount";
import RidePaymentActivity from "./RideAnalytics/RidePaymentActivity";
import RideRevenue from "./RideAnalytics/RideRevenue/RideRevenue";
import RideStatus from "./RideAnalytics/RideCancelStatus";
import Dashboard from "./Dashboard/index";

import TotalSale from "./Sales/ReportSub"
import TotalOrders from './TotalOrders/ReportSub'
import SalesPerformance from "./SalesPerformance";
// import Funnel from "./FunnelChart";
import TruckUtilisation from "./TruckUtilisation";
import DispatchTime from "./DispatchTime";
import OnTimeDelivery from "./OnTimeDelivery";
import TransporterAcceptance from "./TransporterAcceptance";
import TransporterPerformance from "./TransporterPerformance"


class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [


      // {
      //   name: "Trucker Analytics Overview",
      //   url: `${this.props.match.url}/trucker-analytics-overview`,
      //   icon: PollIcon,
      //   component: Dashboard,
      // },

      // {
      //   name: "Trucker Count",
      //   url: `${this.props.match.url}/trucker-count`,
      //   icon: PollIcon,
      //   component: RideCount,
      // },DispatchTime
      {
        name: "Truck Utilisation",
        url: `${this.props.match.url}/truck-utilisation`,
        icon: PollIcon,
        component: TruckUtilisation,
      },

      {
        name: "Dispatch Time",
        url: `${this.props.match.url}/dispatch-time`,
        icon: PollIcon,
        component: DispatchTime,
      },

      {
        name: "On Time Delivery",
        url: `${this.props.match.url}/on-time-delivery`,
        icon: PollIcon,
        component: OnTimeDelivery,
      },

      {
        name: "Transporter Acceptance",
        url: `${this.props.match.url}/transporter-acceptance`,
        icon: PollIcon,
        component: TransporterAcceptance,
      },

      {
        name: "Transporter Performance",
        url: `${this.props.match.url}/transporter-performance`,
        icon: PollIcon,
        component: TransporterPerformance,
      },

      // {
      //   name: "Trucker Payment Activity",
      //   url: `${this.props.match.url}/trucker-payment-activity`,
      //   icon: PollIcon,
      //   component: RidePaymentActivity,
      // },
      {
        name: "Trucker Revenue",
        url: `${this.props.match.url}/ride-revenue`,
        icon: PollIcon,
        component: RideRevenue,
      },
      // {
      //   name: "Trucker Cancel Status",
      //   url: `${this.props.match.url}/trucker-cancel-status`,
      //   icon: PollIcon,
      //   component: RideStatus,
      // },


      // {
      //   // total sale
      //   name: "Sales Over Time",
      //   url: `${this.props.match.url}/total-sale`,
      //   icon: PollIcon,
      //   component: TotalSale,
      // },

      // {
      //   name: "Orders Report",
      //   url: `${this.props.match.url}/orders-report`,
      //   icon: PollIcon,
      //   component: TotalOrders,
      // },

      // {
      //   name: "Sales Performance",
      //   url: `${this.props.match.url}/sales-performance`,
      //   icon: PollIcon,
      //   component: SalesPerformance,
      // },
      // {
      //   name: "Funnel Chart",
      //   url: `${this.props.match.url}/funnel-chart`,
      //   icon: PollIcon,
      //   component: Funnel,
      // },

    ],
  };

  render() {
    if (this.props.location.pathname === "/") {
      this.props.history.push("/dashbord/truck-utilisation");
    }
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/truck-utilisation`}
        ></Header>

        <div
          className="dashbord-body"
          // style={{ height: "100vh", overflow: "auto" }}
        >
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
