import { getForReport } from "../lib/request";

//Graph
export const fetchRideFareData = (
  startDate,
  endDate,
  groupBy,
  currency,
  // status,
  device,
  country_id,
  city_id,
) => {
  let URL = `/trucker/revenue?start_timestamp=${startDate}&end_timestamp=${endDate}&group_by=${groupBy}&currency=${currency}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

export const fetchRideSalesData = (
  startDate,
  endDate,
  currency,
  device,
  country_id,
  city_id,
) => {
  let URL = `/trucker/sales/report?start_timestamp=${startDate}&end_timestamp=${endDate}&compare_with=0&currency=${currency}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

export const fetchRideTableFare = (
  startDate,
  endDate,
  groupBy,
  currency,
  device,
  country_id,
  city_id,
) => {
  let URL = `/trucker/revenue/table?start_timestamp=${startDate}&end_timestamp=${endDate}&group_by=${groupBy}&export=0&vehicle_type_id=0&currency=${currency}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};
