import { getForReport } from "../lib/request";

export const getCountries = () => {
  let URL = "/trucker/countries";
  // let URL = "/python-spark/countries";
  return getForReport(URL);
};

export const getCities = (country_id) => {
  return getForReport(`/trucker/cities?country_id=${country_id}`);
};

export const getZones = (city_id) => {
  return getForReport(`/trucker/zones?city_id=${city_id}`);
};
