

export const tooltipFormatter = (value, currency) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      return Number(kValue.toFixed(2)) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;
      return Number(kValue.toFixed(2)) + " M";
    }
    return Number(value.toFixed(2));
  }